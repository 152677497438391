import { Link } from "gatsby"
import React from "react"
import CookieConsent from "react-cookie-consent"

export default function Footer() {
  return (
    <>
      <div className="footer-copyright text-center py-3">
        &copy; {new Date().getFullYear()} Copyright: Tomasz Rybak
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Rozumiem"
        cookieName="awesomeCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#141e30", fontSize: "14px" }}
        expires={150}
        overlay={true}
      >
        Strona wykorzystuje pliki cookies do prawidłowego działania.{" "}
        <span
          className="moreAboutCookies"
          style={{ fontSize: "10px", color: "#141e30" }}
        >
          <Link to="https://skrypt-cookies.pl/czym-sa-ciasteczka/">
            Więcej informacji
          </Link>
        </span>
      </CookieConsent>{" "}
    </>
  )
}
