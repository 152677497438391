import React from "react"
import { useForm, ValidationError } from "@formspree/react"
import ReCAPTCHA from "react-google-recaptcha"

export default function ContactForm() {
  const [state, handleSubmit] = useForm("xeqvrqky")

  function onChange(value: any) {
    console.log("Captcha value:", value)
  }

  if (state.succeeded) {
    return <p>Thanks for joining!</p>
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="wrapper">
        <label htmlFor="name">Imię i nazwisko</label>
        <input id="name" type="name" name="name" required />
      </div>
      <div className="wrapper">
        <label htmlFor="email">Adres email</label>
        <input id="email" type="email" name="email" required />
      </div>
      <div className="wrapper">
        <label htmlFor="phone">Numer telefonu</label>
        <input type="tel" id="phone" name="phone" required />
      </div>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <textarea
        rows={10}
        id="message"
        name="message"
        required
        placeholder="Twoja wiadomość"
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <ReCAPTCHA
        sitekey="6Lcr8sMbAAAAACoxI7RCs0_NVcTZ67PeI6AWsBhA"
        onChange={onChange}
      />

      <button className="button" type="submit" disabled={state.submitting}>
        Wyślij
      </button>
      <div className="agreement">
        <input type="checkbox" name="agreement" id="checkbox" required />
        <span>
          Wyrażam zgodę na przetwarzanie danych osobowych w związku z wysłaniem
          zapytania poprzez formularz kontaktowy. Podanie danych jest
          dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem
          poinformowany, że przysługuje mi prawo dostępu do swoich danych,
          możliwość ich poprawiania, żądania zaprzestania ich przetwarzania.
          Administratorem danych osobowych jest Radosław Rybak prowadzący
          działalność gospodarczą pod firmą Kancelaria Prawna Radosław Rybak,
          ul. Modrzewiowa 4, 55-003 Jeszkowice.
        </span>
      </div>
    </form>
  )
}
