import React from "react"
import { Nav, Navbar } from "react-bootstrap"

export default function Header() {
  return (
    <>
      <Navbar
        collapseOnSelect
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="mainNav"
        expand="lg"
      >
        <div className="container">
          <Navbar.Brand className="navbar-brand js-scroll-trigger" href="/">
            <img
              src={require("../../static/logo.webp").default}
              alt="Radca Prawny Radosław Rybak logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            className="collapse navbar-collapse"
            id="navbarResponsive"
          >
            <Nav className="navbar-nav ml-auto menu-1">
              <Nav.Link
                className="nav-item nav-link"
                href="/kancelaria/"
                title="RPRR o kancelarii"
              >
                O kancelarii
              </Nav.Link>
              <Nav.Link
                className="nav-item nav-link"
                href="/oferta/"
                title="RPRR oferta"
              >
                Zakres usług
              </Nav.Link>
              <Nav.Link
                className="nav-item nav-link"
                href="/kontakt/"
                title="RPRR kontakt"
              >
                Kontakt
              </Nav.Link>
              {/* <Nav.Link
                className="nav-item nav-link"
                href="/"
                title="RPRR blog"
              >
                Blog
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  )
}
