import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

interface HeadProps {
  title: string
}

export default function Head({ title }: HeadProps) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              siteUrl
            }
          }
        }
      `}
      render={({ site: { siteMetadata: seo } }) => {
        const title = seo.title
        const description = seo.description
        const url = seo.siteUrl
        return (
          <>
            <Helmet>
              {/* General tags */}
              <html lang="en" />
              <title>{title}</title>
              <meta name="description" content={description} />
              <link rel="canonical" href={url} />

              {/* OpenGraph tags */}
              <meta property="og:url" content={url} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
            </Helmet>
          </>
        )
      }}
    />
  )
}
