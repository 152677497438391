import React, { ReactNode } from "react"
import { Header, Footer, Head } from "./index"
import { Container } from "react-bootstrap"
import "../styles/main.scss"

export default function Layout(props: { children: ReactNode }) {
  return (
    <>
      <Head />
      <Header />
      <Container>{props.children}</Container>
      <Footer />
    </>
  )
}
